import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './Accounts.css';
import logo from '../assets/EnterpriseLogo.png';
import AccountModal from './AccountModal';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Accounts = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [accountHolderFilter, setAccountHolderFilter] = useState("");
  const [bookmakerFilter, setBookmakerFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [editingAccount, setEditingAccount] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState("");

  const [visiblePasswords, setVisiblePasswords] = useState({});
  const [editingComment, setEditingComment] = useState(null);
  const [newComment, setNewComment] = useState("");

  const [editingKYC, setEditingKYC] = useState(null); // Track which account's KYC is being edited
  const [updatedKYC, setUpdatedKYC] = useState(""); // Hold updated KYC value

  const statusOptions = ['Dead', 'Limit', 'New', 'Trading'];

  const fetchAccounts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/account/list/`);
      console.log('API Response:', response.data);
      if (response.data) {
        setAccounts(response.data);
        setFilteredAccounts(response.data);
      } else {
        console.error('API Error:', response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Accounts:', error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const filterAccounts = useCallback(() => {
    let filtered = accounts;

    if (accountHolderFilter) {
      filtered = filtered.filter((account) => account.account_holder === accountHolderFilter);
    }
    if (bookmakerFilter) {
      filtered = filtered.filter((account) => account.bookmaker === bookmakerFilter);
    }
    if (statusFilter.length > 0) {
      filtered = filtered.filter((account) => statusFilter.includes(account.status));
    }

    setFilteredAccounts(filtered);
  }, [accounts, accountHolderFilter, bookmakerFilter, statusFilter]);

  useEffect(() => {
    filterAccounts();
  }, [accountHolderFilter, bookmakerFilter, statusFilter, filterAccounts]);

  const uniqueAccountHolders = [...new Set(accounts.map((account) => account.account_holder))];
  const uniqueBookmakers = [...new Set(accounts.map((account) => account.bookmaker))];

  const handleNavigation = (path) => {
    history.push(path);
  };

  const handleLogout = () => {
    history.push('/logout');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddAccount = () => {
    openModal();
  };

  const enableEditingStatus = (account) => {
    setEditingAccount(account.account_id);
    setUpdatedStatus(account.status);
  };

  const handleStatusChange = (e) => {
    setUpdatedStatus(e.target.value);
  };

  const saveUpdatedStatus = async (accountId) => {
    try {
      const url = `${backendUrl}/account/update/status?account_id=${accountId}&status=${updatedStatus}`;

      // Send a GET request
      await axios.get(url);

      // Update the local state with the new status
      setAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.account_id === accountId
            ? { ...account, status: updatedStatus }
            : account
        )
      );

      setEditingAccount(null);
    } catch (error) {
      console.error('Error saving updated status:', error);
    }
  };

  const enableEditingKYC = (account) => {
    setEditingKYC(account.account_id);
    setUpdatedKYC(account.kyc_passed ? "Yes" : "No"); // Map true/false to Yes/No
  };

  const handleKYCChange = (e) => {
    setUpdatedKYC(e.target.value);
  };

  const saveUpdatedKYC = async (accountId) => {
    try {
      const kycBoolean = updatedKYC === "Yes"; // Convert Yes/No back to true/false
      const url = `${backendUrl}/account/update/kyc?account_id=${accountId}&kyc_passed=${kycBoolean}`;
      await axios.get(url);
      await fetchAccounts();

      // Update the local state
      setAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.account_id === accountId
            ? { ...account, kyc_passed: kycBoolean }
            : account
        )
      );
      setEditingKYC(null); // Exit editing mode
    } catch (error) {
      console.error("Error saving updated KYC:", error);
    }
  };

  const handleStatusFilterChange = (e) => {
    const value = e.target.value;
    setStatusFilter((prevStatusFilter) =>
      prevStatusFilter.includes(value)
        ? prevStatusFilter.filter((status) => status !== value)
        : [...prevStatusFilter, value]
    );
  };

  const togglePasswordVisibility = (accountId) => {
    setVisiblePasswords((prevVisiblePasswords) => ({
      ...prevVisiblePasswords,
      [accountId]: !prevVisiblePasswords[accountId],
    }));
  };

  const enableEditingComment = (account) => {
    setEditingComment(account.account_id);
    setNewComment(account.comments);
  };

  const saveUpdatedComment = async (accountId) => {
    try {
      await axios.post(`${backendUrl}/account/comment/save`, {
        accountId,
        comments: newComment,
      });

      setAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.account_id === accountId
            ? { ...account, comments: newComment }
            : account
        )
      );

      setEditingComment(null);
    } catch (error) {
      console.error('Error saving updated comment:', error);
    }
  };

  const cancelEditingComment = () => {
    setEditingComment(null);
    setNewComment("");
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <img className="dashboard-logo" src={logo} alt="Logo" />
        <h1>The Enterprise</h1>
      </div>
      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => handleNavigation('/dashboard')}>
          Home
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/accounts')}>
          Accounts
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/wallets')}>
          Wallets
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/transactions')}>
          Transactions
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/bets')}>
          Bets
        </button>
        <button className="nav-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <hr className="dashboard-line" />

      <div className="filters-add-account-container">
        <div className="filters-container">
          <div className="filter">
            <label>Filter by Account Holder: </label>
            <select
              value={accountHolderFilter}
              onChange={(e) => setAccountHolderFilter(e.target.value)}
            >
              <option value="">All</option>
              {uniqueAccountHolders.map((holder) => (
                <option key={holder} value={holder}>
                  {holder}
                </option>
              ))}
            </select>
          </div>

          <div className="filter">
            <label>Filter by Bookmaker: </label>
            <select
              value={bookmakerFilter}
              onChange={(e) => setBookmakerFilter(e.target.value)}
            >
              <option value="">All</option>
              {uniqueBookmakers.map((bookmaker) => (
                <option key={bookmaker} value={bookmaker}>
                  {bookmaker}
                </option>
              ))}
            </select>
          </div>

          <div className="filter">
            <label>Filter by Status: </label>
            {statusOptions.map((status) => (
              <label key={status} style={{ marginLeft: '10px' }}>
                <input
                  type="checkbox"
                  value={status}
                  checked={statusFilter.includes(status)}
                  onChange={handleStatusFilterChange}
                />
                {status}
              </label>
            ))}
          </div>
        </div>

        <button className="add-account-button" onClick={handleAddAccount}>
          + Add Account
        </button>
      </div>

      <AccountModal isOpen={isModalOpen} onClose={closeModal} />

      <div className="accounts-container">
        {isLoading ? (
          <p>Loading Accounts...</p>
        ) : (
          <table className="accounts-table">
            <thead>
              <tr>
                <th>Account id</th>
                <th>Bookmaker</th>
                <th>Username</th>
                <th>Password</th>
                <th>Email</th>
                <th>Account Holder</th>
                <th>KYC Passed</th>
                <th>Status</th>
                <th>VPN Used</th>
                <th>VPN Country Used</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {filteredAccounts.map((account) => (
                <tr key={account.account_id}>
                  <td>{account.account_id || 'N/A'}</td>
                  <td>{account.bookmaker || 'N/A'}</td>
                  <td>{account.username || 'N/A'}</td>
                  <td>
                    {visiblePasswords[account.account_id] ? (
                      <span>{account.password || 'N/A'}</span>
                    ) : (
                      <span>******</span>
                    )}
                    <button onClick={() => togglePasswordVisibility(account.account_id)}>
                      {visiblePasswords[account.account_id] ? "Hide" : "Show"}
                    </button>
                  </td>
                  <td>{account.email || 'N/A'}</td>
                  <td>{account.account_holder || 'N/A'}</td>
                  <td>
                    {editingKYC === account.account_id ? (
                      <div>
                        <select value={updatedKYC} onChange={handleKYCChange}>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <button onClick={() => saveUpdatedKYC(account.account_id)}>Save</button>
                      </div>
                    ) : (
                      <span>
                        {account.kyc_passed ? "Yes" : "No"}{" "}
                        <button onClick={() => enableEditingKYC(account)}>Edit</button>
                      </span>
                    )}
                  </td>
                  <td>
                    {editingAccount === account.account_id ? (
                      <div>
                        <select value={updatedStatus} onChange={handleStatusChange}>
                          {statusOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <button onClick={() => saveUpdatedStatus(account.account_id)}>Save</button>
                      </div>
                    ) : (
                      <span>
                        {account.status || 'N/A'}{" "}
                        <button onClick={() => enableEditingStatus(account)}>Edit</button>
                      </span>
                    )}
                  </td>
                  <td>{account.vpn_used ? "Yes" : "No"}</td>
                  <td>{account.vpn_country_used || 'N/A'}</td>
                  <td>
                    {editingComment === account.account_id ? (
                      <div>
                        <input
                          type="text"
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                        />
                        <button onClick={() => saveUpdatedComment(account.account_id)}>Save</button>
                        <button onClick={cancelEditingComment}>Cancel</button>
                      </div>
                    ) : (
                      <span>
                        {account.comments || 'N/A'}{" "}
                        <button onClick={() => enableEditingComment(account)}>Edit</button>
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Accounts;
