import React, { useState } from 'react';
import './AccountModal.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AccountModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        bookmaker: '',
        username: '',
        password: '',
        email: '',
        account_holder: '',
        kyc_passed: '',
        vpn_used: '',  // Default to 'No'
        vpn_country_used: ''
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${backendUrl}/account/add/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccessMessage('Account added successfully!');
                setErrorMessage('');
                setTimeout(() => {
                    setSuccessMessage(''); // Clear success message after a few seconds
                    onClose(); // Close modal after successful addition
                }, 2000);
            } else {
                setErrorMessage('Error adding account, please try again.');
            }
        } catch (error) {
            setErrorMessage('Error adding account: ' + error.message);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Add Account</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}
                <form className="account-form" onSubmit={handleSubmit}>
                    <label>
                        Bookmaker:
                        <input
                            type="text"
                            name="bookmaker"
                            value={formData.bookmaker}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Username:
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Password:
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Account Holder:
                        <input
                            type="text"
                            name="account_holder"
                            value={formData.account_holder}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        KYC passed:
                        <input
                            type="text"
                            name="kyc_passed"
                            value={formData.kyc_passed}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        VPN Used:
                        <input
                            type="text"
                            name="vpn_used"
                            value={formData.vpn_used}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        VPN Country Used:
                        <input
                            type="text"
                            name="vpn_country_used"
                            value={formData.vpn_country_used}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <button type="submit" className="save-button">Save</button>
                </form>
            </div>
        </div>
    );
};

export default AccountModal;
